import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "../components/lightbox"
import SimpleReactLightbox from "simple-react-lightbox"
import Video from "../components/video"
import SectionTitlePC from "../images/title_section_pc.png"
import SectionTitleSP from "../images/title_section_sp.png"
import Flow01 from "../images/icon_flow_01.svg"
import Flow02 from "../images/icon_flow_02.svg"
import Flow03 from "../images/icon_flow_03.svg"
import IndexStyle from "../css/index.module.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="マコなり社長×Allbirds「感謝の手紙」キャンペーン" description="「感謝の手紙」キャンペーン＿＿大切な人に「感謝の手紙」を書く＆「#マコなり社長オールバーズ感謝の手紙」をつけてツイート感謝を伝えてAllbirdsの靴を手に入れよう" />
    <section className="bg-white sm:w-4/5 mx-auto relative max-w-screen-lg overflow-hidden sm:shadow-2xl">
      <div className="sm:w-4/6 mx-auto text-center py-10 sm:py-20 relative z-10">
        <img src={SectionTitlePC} alt="「感謝の手紙」を書こう" className="hidden sm:block sm:w-4/5 mx-auto" />
        <img src={SectionTitleSP} alt="「感謝の手紙」を書こう" className="block sm:hidden w-4/5 mx-auto" />
        <p className="pt-10 px-10 sm:px-0">
          日頃、なかなか感謝を伝えられていないあなたに「感謝の手紙」を書く機会を通じて
          <br />
          今この瞬間が「ありがたい」ことを実感して欲しい。
          <strong className="block text-lg pt-5 mb-5">
            そんな想いを込めて
            <span className="text-2xl px-2 block">#マコなり社長オールバーズ感謝の手紙</span> キャンペーンを開催します。
          </strong>

          <div className="mt-10">
            <Video
              videoSrcURL="//www.youtube.com/embed/HRMMEmTSVf0"
              videoTitle="マコなり社長"
            />
          </div>
        </p>
      </div>
      <div className={`${IndexStyle.letterObject} absolute top-0 -mt-24 sm:mt-0`}>
        <svg width="1200" height="249" className="mx-auto" viewBox="0 0 1200 249" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M600 249L2.17683e-05 1.90419e-06L1200 0.000106812L600 249Z" fill="#F8F7F5"/>
        </svg>
      </div>
    </section>
    <section className="">
      <h2 className="relative leading-10 py-10 mt-5 sm:mt-20 mb-5 sm:mb-10 text-4xl font-bold text-center">
        応募の流れ
        <span className="absolute uppercase text-opacity-25 font-bold italic text-bgdark">Application Process</span>
      </h2>
      <div className="w-11/12 max-w-screen-lg sm:w-4/5 mx-auto">
        <ul className="sm:flex justify-between flex-wrap">
          <li className="sm:pr-1 sm:w-1/2 mb-4 sm:mb-0">
            <div className="bg-white h-full">
              <h4 className="font-bold italic text-bgdark text-4xl text-center">
                STEP <span className="text-6xl">1</span>
                <span className="block not-italic text-black">参加表明をする</span>
              </h4>
              <div className="p-5">
                <p>ハッシュタグ</p>
                <div className="bg-sub font-bold text-xl p-2 my-2 text-center">
                  #マコなり社長オールバーズ感謝の手紙 
                </div>
                <p>をつけて参加表明のツイート</p>
                <div className="twitter pt-5 text-center">
                  <p>下のボタンからツイートしよう</p>
                  <a href="https://twitter.com/intent/tweet?hashtags=マコなり社長オールバーズ感謝の手紙&url=https://bit.ly/3pxmfOO" 
                    rel="nofollow" 
                    target="_blank"
                    className="px-2 py-4 flex items-center justify-center bg-twitter rounded-md block text-center font-bold text-white mt-2 no-underline">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" className="inline-block h-5 mr-2"><path fill="#fff" d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z"/></svg>
                      参加表明のツイートをする
                  </a>
                  <p className="mt-2">※捨てアカウント、懸賞用アカウント、鍵付きのアカウントでの応募は無効</p>
                </div>
              </div>
            </div>
          </li>
          <li className="sm:pl-1 sm:w-1/2 mb-4 sm:mb-0">
            <div className="bg-white h-full">
              <h4 className="font-bold italic text-bgdark text-4xl text-center">
                STEP <span className="text-6xl">2</span>
                <span className="block not-italic text-black">手紙を書く</span>
              </h4>
              <div className="p-5">
                <p>
                  感謝を伝えたい相手に紙の便箋やハガキに<span className="text-orange-500">直筆</span>で感謝の手紙を書く
                  <span className="text-sm mt-4 text-gray-600 block">
                    ・文量は自由です
                    <br />
                    ・手紙は想いを込めてしっかり書いてください
                    <br />
                    ※便箋や封筒、ハガキ等はご自身でご用意ください
                  </span>
                </p>
              </div>
              <img src={Flow01} alt="紙の便箋やハガキに直筆で感謝の手紙を書く" className="block w-4/6 sm:w-2/4 mx-auto pb-5" />
            </div>
          </li>
        </ul>
        <ul className="sm:flex sm:mt-2 justify-between flex-wrap">
          <li className="sm:pr-1 sm:w-1/2 mb-4 sm:mb-0">
            <div className="bg-white h-full">
              <h4 className="font-bold italic text-bgdark text-4xl text-center">
                STEP <span className="text-6xl">3</span>
                <span className="block not-italic text-black">撮影をする</span>
              </h4>
              <div className="p-5">
                <p className="pb-2">
                  感謝の手紙全体が写った画像をスマートフォンで撮影＆画像URLを生成する
                  <br />
                  <span className="text-sm mt-4 text-gray-600 block">※画像URLの生成方法は下記「応募の注意点」参照</span>
                </p>
              </div>
              <img src={Flow02} alt="感謝の手紙全体が写った画像をスマートフォンで撮影" className="block w-4/6 sm:w-2/4 mx-auto pb-5" />
            </div>
          </li>
          <li className="sm:pl-1 sm:w-1/2 mb-4 sm:mb-0">
            <div className="bg-white h-full">
              <h4 className="font-bold italic text-bgdark text-4xl text-center">
                STEP <span className="text-6xl">4</span>
                <span className="block not-italic text-black">手紙を渡す</span>
              </h4>
              <div className="p-5">
                感謝を伝える相手に手紙を渡す
              </div>
              <img src={Flow03} alt="感謝を伝える相手に手紙を渡す" className="block w-4/6 sm:w-2/4 mx-auto pb-5" />
            </div>
          </li>
        </ul>
        <ul className="sm:flex sm:mt-2 justify-between flex-wrap">
          <li className="sm:pl-1 sm:w-1/2 mb-4 sm:mb-0">
            <div className="bg-white h-full">
              <h4 className="font-bold italic text-bgdark text-4xl text-center">
                STEP <span className="text-6xl">5</span>
                <span className="block not-italic text-black">フォームを送信する</span>
              </h4>
              <div className="p-5">
                
                <ol className="list-decimal ml-4 mb-2">
                  <li>手紙の画像URL</li>
                  <li>ハッシュタグ「#マコなり社長オールバーズ感謝の手紙」をつけたツイートURL</li>
                </ol>
                <p>
                  2つを添付して下のフォームから送信
                  <span className="text-sm mt-4 text-gray-600 block">
                    当選は先着順ではありません。
                    <br />
                    しっかりと想いを伝える手紙を書いて期間内にご応募ください。
                  </span>
                </p>
                <div className="px-2 py-4 rounded bg-gray-400 block text-center text-gray-600 no-underline mt-5 font-bold">応募は締め切りました</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <h2 className="relative leading-10 py-10 mt-8 sm:mt-20 mb-5 sm:mb-10 text-4xl font-bold text-center">
        応募の注意点
        <span className="absolute uppercase text-opacity-25 font-bold italic text-bgdark">Application Notes</span>
      </h2>
      <div className="sm:w-4/5 max-w-screen-lg mx-auto">
        <div className="bg-white p-5 sm:p-10">
          <div className="left">
            <h4 className="text-2xl mb-2 font-bold">写真の撮り方</h4>
            <div className="sm:flex items-center">
              <div className="sm:w-2/6 shadow-md sm:mr-8">
                <SimpleReactLightbox>
                  <Lightbox />
                </SimpleReactLightbox>
              </div>
              <div>
                <ul className="list-disc font-bold mx-5">
                  <li>全体がわかるように写真を撮ってください</li>
                  <li>個人情報にはぼかしを入れても大丈夫です</li>
                </ul>
                <div className="mt-5">
                  ※画像は事務局にて確認し、キャンペーンにそぐわないと判断した応募は無効とさせていただきます
                  <br />
                  <span className="font-bold block mt-5">＜無効になる応募の例＞</span>
                  <ul className="list-disc mx-5">
                    <li>本人が直筆で書いた手紙の画像以外での応募</li>
                    <li>同一写真での複数回応募</li>
                    <li>「感謝を伝える手紙」として成立していない手紙の内容での応募</li>
                    <li>公序良俗に反している手紙の内容での応募</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-5 sm:p-10 mt-5 sm:mt-10">
          <h4 className="text-2xl mb-2 font-bold">画像URL生成の方法</h4>
          <p>
            画像URLを取得できるサービスを利用するもしくは
            <br />
            検索エンジンで「画像URL」などと検索して表示されるサイトに手紙の画像をアップしてURLを生成してください。
            <br />
            <strong className="font-bold block mt-3">例</strong>
          </p>
          <ul className="list-disc ml-5">
            <li>
              <a href="https://d.kuku.lu/" className
              ="block font-bold mb-1">ファイルなう</a>
            </li>
            <li>
              <a href="https://help.dropbox.com/ja-jp/files-folders/share/view-only-access" className
              ="block font-bold mb-1">Dropboxで画像URLを取得</a>
            </li>
            <li>
              <a href="https://support.apple.com/ja-jp/guide/icloud/mm93a9b98683/icloud" className
              ="block font-bold mb-1">iCloudリンク</a>
            </li>
          </ul>
          <p>
            <span className="text-sm mt-4 text-gray-600 block">※画像アップロードに関するトラブルについては責任を負いかねます</span>
          </p>
        </div>
      </div>
    </section>

    <section>
      <h2 className="relative leading-10 py-10 mt-5 sm:mt-20 mb-5 sm:mb-10 text-4xl font-bold text-center">
        応募フォーム
        <span className="absolute uppercase text-opacity-25 font-bold italic text-bgdark">Application Form</span>
      </h2>
      <div className="w-11/12 max-w-screen-lg sm:w-1/2 mx-auto bg-bgdark bg-opacity-25 p-5 py-10 sm:p-10 mb-10 text-center">
        <h4 className="font-bold mt-0 pt-0 leading-8 italic text-center text-6xl">1</h4>
        <h3 className="font-bold py-5 text-center text-2xl">参加表明ツイート</h3>
        ハッシュタグ
        <span className="text-xl px-2">#マコなり社長オールバーズ感謝の手紙</span>
        をつけて
        <br />
        <a href="https://twitter.com/intent/tweet?hashtags=マコなり社長オールバーズ感謝の手紙&url=https://bit.ly/3pxmfOO" 
          rel="nofollow" 
          target="_blank"
          className="px-2 py-4 flex items-center justify-center bg-twitter rounded-md block text-center font-bold text-white no-underline mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" className="inline-block h-5 mr-2"><path fill="#fff" d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z"/></svg>
            参加表明のツイートをする
        </a>
        <br />
        <p>
          ※捨てアカウント、懸賞用アカウント、鍵付きのアカウントでの応募は無効
        </p>
      </div>
      <div className="w-11/12 sm:w-1/2 mx-auto bg-bgdark bg-opacity-25 p-5 py-10 sm:p-10 mb-10 text-center">
        <h4 className="font-bold mt-0 pt-0 leading-8 italic text-center text-6xl">2</h4>
        <h3 className="font-bold py-5 text-center text-2xl">2つのURLが準備できたらフォームから応募する</h3>
        <div className="mx-auto">
          <ul className="list-disc ml-5 text-left mb-2">
            <li>
              TwitterでツイートしたURL
            </li>
            <li>
              感謝の手紙全体が写った画像を撮影し、生成したURL
            </li>
          </ul>
          <p className="mb-5">
            
          </p>
          <div className="px-2 py-4 rounded bg-gray-400 block text-center text-gray-600 no-underline mt-5 font-bold">応募は締め切りました</div>
          <br />
          <a href="https://www.notion.so/Allbirds-FAQ-d8b8261307d44b728f5a707c37bf3e72" target="_blank" className="text-blue">よくあるご質問はこちら</a>
        </div>
      </div>
      <div className="w-11/12 sm:w-1/2 mx-auto">
        <h4 className="text-center font-bold text-xl pb-4">Allbirds HP</h4>
        <p>
          最新情報や製品はHP・公式オンラインストアからご確認ください
          <br />
          <a href="https://allbirds.jp/">https://allbirds.jp/</a>
        </p>
      </div>
    </section>

    <section>
      <h2 className="relative leading-10 py-10 mt-5 sm:mt-20 mb-5 sm:mb-10 text-4xl font-bold text-center">
        募集要項
        <span className="absolute uppercase font-bold italic text-bgdark text-opacity-25">Application Guidelines</span>
      </h2>
      <div className="w-11/12 sm:w-1/2 mx-auto text-sm">
        <h4 className="font-bold py-2">【賞品内容・当選者数】</h4>
        <p>Allbirds原宿店・ECで使用できるギフトカード12,500円分</p>
        <h4 className="font-bold py-2">【応募期間】</h4>
        <p>2020年11月20日（金）20:00　〜　2020年11月29日（日）23:59</p>
        <h4 className="font-bold py-2">【応募条件】</h4>
        <ul className="list-disc mx-5">
          <li>公序良俗に反する手紙の画像で応募をしていないこと。</li>
          <li>Twitterアカウントをお持ちであること。</li>
          <li>応募期間内にハッシュタグ #マコなり社長オールバーズ感謝の手紙 をつけてツイートしていること。</li>
        </ul>
        <p>※捨てアカウント、懸賞用アカウント、鍵付きのアカウントでの応募は無効になります</p>
        <p>※下記に該当される方は、Twitterの仕様上、第三者がツイートを閲覧できない場合がございます。ご注意ください</p>
        <ul className="list-disc mx-5">
          <li>プロフィール、ユーザー名、自己紹介、アイコンなどを設定していないアカウント</li>
          <li>頻繁にアクション（ツイート、リツイート、いいね、返信など）を行っていないアカウント</li>
          <li>Twitterアカウント開設後、メールアドレスが本人のものか確認をするプロセスを行っていないアカウント</li>
          <li>Twitterアカウント開設直後のアカウント、Twitterルールに反していると判断されるアカウント※詳しくは、<a href="https://help.twitter.com/ja/rules-and-policies/twitter-rules">Twitterルール</a>をご確認ください。</li>
        </ul>
        <h4 className="font-bold py-2">【応募方法】</h4>
        <ol className="list-decimal mx-5">
          <li>ハッシュタグ 「#マコなり社長オールバーズ感謝の手紙」をつけて参加表明のツイートをする</li>
          <li>感謝を伝えたい相手に紙の便箋やハガキに直筆で感謝の手紙を書く</li>
          <li>感謝の手紙全体が写った画像をスマートフォンで撮影して画像URLを生成する</li>
          <li>感謝を伝えたい相手に手紙を渡す</li>
          <li>指定のフォームから応募<span>　（手紙の画像URL＆ハッシュタグ「#マコなり社長オールバーズ感謝の手紙」をつけたツイートのURLを添付）</span></li>
        </ol>
        <p>※画像は事務局にて確認し、キャンペーンにそぐわないと判断したご応募は無効とさせていただきます</p>
        <ul className="list-disc mx-5">
          <li>本人が直筆で書いた手紙の画像以外での応募</li>
          <li>同一写真での複数回応募</li>
          <li>公序良俗に反している手紙の内容での応募</li>
          <li>「感謝を伝える手紙」として成立していない手紙での応募</li>
        </ul>
        <h4 className="font-bold py-2">【当選発表】</h4>
        <p>ギフトカードの発送をもって当選発表とさせていただきます。</p>
        <p>※抽選結果や抽選方法に関するご質問の受付は行っておりません。</p>
        <h4 className="font-bold py-2">【運営事務局について】</h4>
        <p>本キャンペーンは、株式会社マコなりならびにAllbirds 合同会社が主催し、キャンペーン事務局（以下、事務局）が運営しております。</p>
        <p>なお、適正な運用を行うために事務局が必要と判断した場合に限り、本キャンペーンの応募条件変更・中止等、あらゆる対応をとることができるものとさせていただきます。あらかじめご了承ください。詳しくは【その他の注意事項】をご確認ください。</p>
        <h4 className="font-bold py-2">【その他の注意事項】</h4>
        <ul className="list-disc mx-5">
          <p>本キャンペーンについては下記をお読みいただき、ご同意いただける場合のみご応募ください。</p>
          <li>応募は1人1回限りです。複数回の応募をされた場合は全ての応募が無効となります。</li>
          <li>本キャンペーンに応募するにあたり、応募者はキャンペーンの運用について事務局の運用方法に従うものとし、一切異議申立てを行わないものとします。</li>
          <li>本キャンペーンに想定以上の応募があった場合、応募期間内であっても応募を締め切る場合があります。</li>
          <li>本キャンペーンの応募フォームでお送りいただいた画像や個人情報は、当選者の抽選や発送等を実施するために使用し、キャンペーン終了次第破棄します。</li>
          <li>本キャンペーンについての連絡及び賞品発送に関して、事務局からご連絡をさせていただく場合がございます。</li>
          <li>本キャンペーンページの利用・利用停止、もしくは不能による損害については事務局は一切責任を負いかねます。（各種Webサービスのサーバダウン等も含む）</li>
          <li>本キャンペーンはパソコン、スマートフォンからのご応募となります。稀に、機種によってはご利用できない場合がございます。フィーチャーフォン（ガラケー）からのご応募はできません。</li>
          <li>インターネット接続料及び通信料はお客様のご負担となります。</li>
          <li>賞品のお届け先は、日本国内に限らせていただきます。</li>
          <li>賞品の内容及びデザインは変更となる場合がございます。あらかじめご了承ください。</li>
          <li>当選の権利は当選者ご本人のみに帰属します。当選の権利・賞品の第三者への譲渡及び換金は固く禁止いたします。</li>
          <li>当選の権利や賞品をオークション等に出品して換金することは固くお断りいたします。オークション等への出品が確認された場合は、当選を無効とし、賞品をご返却いただく場合がございます。</li>
          <li>ご当選者様の賞品送付先として同一の住所や、電話番号が複数登録された場合、応募を無効とさせていただく場合がございます。あらかじめご了承ください。</li>
          <li>当社が意図しない不正な処理を行わせるプログラム等でのご応募は無効とさせていただきます。</li>
          <li>当サイトの利用によるいかなるトラブルや損害（直接、間接の損害別を問わず）が発生したとしても株式会社マコなりならびにAllbirds 合同会社は一切の責任を負いかねます。あらかじめご了承ください。</li>
          <li>ご応募いただく際にお預かりする皆様の個人情報の保護方針については、「<a href="https://www.notion.so/eebe07bb2ac4498a8bee34b8fe7a7d1d">プライバシーポリシー</a>」に記載しておりますので、必ずご一読の上、ご利用願います。</li>
          <li>本キャンペーンは株式会社divとは一切関係ありません。</li>
        </ul>
        <h4 className="font-bold py-2">【賠償責任】</h4>
        <p>本サイトの利用に関連して利用者と第三者との間に紛争が発生した場合には、利用者は、自身の責任と費用で当該紛争を解決するものとし、株式会社マコなりならびにAllbirds 合同会社に損害を与えることのないようにするものとします。</p>
        <h4 className="font-bold py-2">【免責事項】</h4>
        <p>本サイトの利用に関連して利用者または第三者に損害が発生した場合、株式会社マコなりならびにAllbirds 合同会社は一切の責任を負いかねます。</p>
        <p>また、本キャンペーンの規約に違反していると事務局が判断した場合に限り、アカウントのブロック、当選の取り消し等の必要な措置をとることができるものとし、これに関連して利用者に損害が発生した場合についても事務局は責任を負わないものとします。</p>
        <h4 className="font-bold py-2">【準拠法および裁判管轄】</h4>
        <p>本キャンペーン規約の解釈・適用に関しましては、日本国法に準拠するものといたします。</p>
        <p> また、本キャンペーン規約に関する紛争については、東京地方裁判所を第一審の専属管轄裁判所とします。</p>
        <h4 className="font-bold py-2">【商品に関するお問い合わせ】</h4>
        <p>help@allbirds.jp</p>
        <p>※企画内容や抽選方法、抽選結果に関するお問い合わせは一切受け付けません</p>
        <p>※関係者へのTwitterのDM（ダイレクトメッセージ）でのお問い合わせについては、誠に恐れ入りますがお受けできませんのであらかじめご了承ください</p>

      </div>

    </section>
  </Layout>
)

export default IndexPage
