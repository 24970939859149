import React from "react"
import VideoStyle from "../css/video.module.scss"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className={`${VideoStyle.video} relative block h-0 p-0 overflow-hidden`}>
    <iframe
      className="absolute top-0 left-0 bottom-0 w-full h-full"
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video