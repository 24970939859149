import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import LetterImg from "../images/makonari_letter.jpg"
import LetterImgThumb from "../images/makonari_letter_thumb.jpg"

function Lightbox() {
  return (
    <div className="Lightbox">
      <SRLWrapper>
        <a href={LetterImg} data-attribute="SRL">
          <img src={LetterImgThumb} />
        </a>
      </SRLWrapper>
    </div>
  );
}

export default Lightbox
